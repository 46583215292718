import React from "react"
import { Link } from "gatsby"
import {
  Card,
  CardTitle,
  CardText,
  CardSubtitle,
  CardBody,
  Badge,
  Row,
  Col
} from "reactstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { slugify } from "../utils/utilityFunctions"



const Post = ({ title, author, slug, date, body, fluid, tags }) => {

  return (
    <Card className="padded">

      <Row>

        <Col md="5">
          <CardBody>
            <div className="card-image">
              <GatsbyImage image={getImage(fluid)} alt="Description of the image" />
            </div>
          </CardBody>
        </Col>

        <Col md="7">

          <CardBody>
            <CardTitle>
              <p className="card-title-font"><Link to={`/post/${slug}`}>{title}</Link></p>
            </CardTitle>
            <CardSubtitle>
              <span>Posted on </span>
              <span className="text-info">{date}</span> by{" "}
              <span className="text-info">{author}</span>
              <div>
                <span>Under{" "}<ul className="post-tags">
                  {tags.map(tag => {
                    return (
                      <li key={tag}>
                        <Link to={`/tag/${slugify(tag)}`}>
                          <Badge color="primary">{tag}</Badge>
                        </Link>
                      </li>
                    )
                  })}
                </ul></span></div>
            </CardSubtitle>

            <CardText>{body}</CardText>
          </CardBody>
        </Col>
      </Row>
      <div className="text-center border-before border-after">


        <Link to={`/post/${slug}`}>
          Continue Reading
        </Link>
      </div>
    </Card>
  )
}
export default Post
